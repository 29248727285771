<template>
  <b-card no-body>
    <b-row>
      <b-col md="3" class="mb-1">
        <b-button
          v-if="$hascan('entrada.registrar')"
          variant="primary"
          :to="{ name: 'entrada-nuevo' }"
          class="mr-1"
        >
          <feather-icon icon="PlusIcon" class="mr-50" />
          <span class="align-middle">Nuevo</span>
        </b-button>

        <b-button
          @click="actualizarLista()"
          variant="secondary"
          class="btn-icon mr-1"
        >
          <feather-icon icon="RotateCcwIcon" />
        </b-button>
      </b-col>
      <b-col md="4">
        <b-row>
          <b-col cols="6">
            <b-form-group label-cols="3" label-cols-lg="3" label="Del">
              <flat-pickr v-model="del" class="form-control" />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label-cols="3" label-cols-lg="3" label="Al">
              <flat-pickr v-model="al" class="form-control" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="3" class="mb-1">
        <v-select
          label="title"
          placeholder="Filtrar por tipo de entrada"
          v-model="tipo_entrada_id"
          :options="tipoEntradaOpciones"
        />
      </b-col>
      <b-col md="2">
        <b-form-group
          label=""
          label-align-sm="right"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group>
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              @keyup.enter="onFiltered()"
              placeholder="Buscar.."
            />
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12" class="my-1">
        <b-overlay :show="loading">
          <b-table
            class="position-relative sm"
            responsive="sm"
            ref="selectableTable"
            selectable
            select-mode="single"
            hover
            striped
            :small="true"
            :items="items"
            :fields="fields"
            @row-selected="onRowSelected"
            show-empty
            empty-text="No se encontraron registros coincidentes"
          >
            <template #cell(selected)="{ rowSelected }">
              <template v-if="rowSelected">
                <i class="feather icon-disc primary" />
              </template>

              <template v-else>
                <i class="feather icon-circle" />
              </template>
            </template>

            <template #cell(titulo)="data">
              <h5>
                <strong>{{ data.value }}</strong>
              </h5>
              <strong class="text-primary">Url: </strong>{{ data.item.url }}
            </template>

            <template #cell(estado)="data">
              <b-badge :variant="estado[1][data.value]">
                {{ estado[0][data.value] }}
              </b-badge>
            </template>

            <template #cell(accion)="data">
              <div class="text-nowrap">
                <b-button
                  @click="publicar_facebook(data.item)"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  v-b-tooltip.hover.bottom="'Publicar Facebook'"
                  class="btn-icon mr-1"
                  size="sm"
                >
                  <feather-icon icon="FacebookIcon" />
                </b-button>
                <b-button
                  v-if="$hascan('entrada.editar')"
                  :to="{ name: 'entrada-editar', params: { id: data.item.id } }"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  v-b-tooltip.hover.bottom="'Editar'"
                  class="btn-icon mr-1"
                  size="sm"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>

                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-dark"
                  @click="imprimirEntradaPdf(data.item.id)"
                  v-b-tooltip.hover.bottom="'Imprimir PDF'"
                  class="btn-icon mr-1"
                  size="sm"
                >
                  <feather-icon icon="PrinterIcon" />
                </b-button>

                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-dark"
                  v-b-tooltip.hover.bottom="'Previsualizar'"
                  class="btn-icon mr-1"
                  target="_blank"
                  size="sm"
                  :href="`${mixing.app_url}publicaciones/${data.item.tipo_entrada.url}/${data.item.url}?preview=${data.item.sha}`"
                >
                  <feather-icon icon="EyeIcon" />
                </b-button>

                <b-button
                  v-if="$hascan('entrada.papelera')"
                  @click="confirmar_mover_papelera(data.item.id)"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-danger"
                  v-b-tooltip.hover.bottom="'Papelera'"
                  class="btn-icon"
                  size="sm"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </div>
            </template>
            <template #cell()="data">
              <span class="text-nowrap">{{ data.value }}</span>
            </template>
          </b-table>
        </b-overlay>
      </b-col>
      <b-col cols="12">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="right"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import axiosIns from "@/libs/axios";
import {
  BCard,
  BCardText,
  BTable,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BDropdown,
  BDropdownItem,
  BOverlay,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
const Spanish = require('flatpickr/dist/l10n/es');

export default {
  components: {
    BCard,
    BCardText,
    BTable,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    BOverlay,
    vSelect,
    flatPickr,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      items: [],
      fields: [
        { key: "tipo_entrada.nombre", label: "Tipo", sortable: false },
        { key: "published_at", label: "Fecha", sortable: false },
        { key: "estado", label: "Estado", sortable: false },
        { key: "titulo", label: "Titulo", sortable: false },
        { key: "user.name", label: "Autor", sortable: false },
        //{ key: 'categoria.nombre', label: 'Categoria', sortable: false },
        { key: "created_at", label: "Creado", sortable: false },
        "accion",
      ],
      facebook_pagina: "374381306383341",
      selected: [],
      filter: "",
      filterOn: [],
      perPage: 10,
      pageOptions: [10, 25, 50],
      totalRows: 1,
      currentPage: 1,
      loading: false,
      estado: [
        {
          1: "Publicado",
          2: "Borrador",
          3: "Papelera",
        },
        {
          1: "light-primary",
          2: "light-warning",
          3: "light-danger",
        },
      ],
      item: [],
      categoria_id: "",
      categoriaOpciones: [],
      tipo_entrada_id: "",
      tipoEntradaOpciones: [],
      del: null,
      al: null,
    };
  },
  created() {
    this.loading = true;
    this.rango_fechas();
    this.listarTipoEntradas();
  },
  watch: {
    categoria_id: function () {
      this.loading = true;
      this.listar();
    },
    tipo_entrada_id: function () {
      this.loading = true;
      this.listar();
    },
    del: function () {
      if (!this.loading) {
        this.loading = true;
        this.listar();
      }
    },
    al: function () {
      if (!this.loading) {
        this.loading = true;
        this.listar();
      }
    },
    currentPage: function () {
      if (!this.loading) {
        this.loading = true;
        this.listar();
      }
    },
    filter: function (val) {
      if (val == "" || val.length == 0) {
        this.loading = true;
        this.listar();
      }
    },
  },
  methods: {
    loguu() {
      window.FB.getLoginStatus(function (response) {
        //console.log(response);
      });
    },
    publicar_facebook(item) {
      window.FB.getLoginStatus((response) => {
        //console.log(response);
        if ((response.status == "connected")) {
          var url = `${mixing.app_url}publicaciones/${item.tipo_entrada.url}/${item.url}`;

          var descripcion = item.titulo + " " + item.descripcion;
          //console.log(descripcion);
          //var messagefb=descripcion.replace(/<[^>]*>/g, '');
          var messagefb = descripcion
            .replace(/(<(?:.|\n)*?>)/gm, " ")
            .replace(/\s+/gm, " ");
          var messagefb=messagefb.replace(/(&nbsp;)*/g, '');
         // console.log(messagefb);
          window.FB.api(
            `/${this.facebook_pagina}/feed`,
            "POST",
            {
              caption: item.titulo,
              message: messagefb,
              link: url,
              access_token: localStorage.getItem("fb_pag"),
            },
            (response) => {
              if(response.error){
                this.mensaje("Error al publicar, tiene que obtener el token de la pagina","warning");
              }else{
                //console.log("insertado");
              //console.log(response);
              this.mensaje("Publicado en Facebook con Exito","success");

              }
              
            }
          );
        }
        else{
          //console.log("tiene que estar logueado para conectarse");
          this.mensaje("Error al publicar, tiene que estar logueado para publicar","warning");
        }

        
      });

      //console.log(item);
    },
    async mensaje(Mensaje,tipo) {
      await this.$swal({
        title: Mensaje,

        icon: tipo,
        timer: 3000,
        confirmButtonText: "Aceptar",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    selectThirdRow() {
      this.$refs.selectableTable.selectRow(2);
    },
    unselectThirdRow() {
      this.$refs.selectableTable.unselectRow(2);
    },
    onFiltered() {
      if (this.currentPage != 1) {
        this.currentPage = 1;
      } else {
        this.loading = true;
        this.listar();
      }
    },
    listar() {
      let tipo_entrada = "";
      if (
        this.tipo_entrada_id == null ||
        this.tipo_entrada_id == "" ||
        this.tipo_entrada_id == "undefined"
      ) {
        tipo_entrada = "";
      } else {
        tipo_entrada = this.tipo_entrada_id.value;
      }
      //var url= '/admin/entradas?page='+this.currentPage;
      var url =
        "/admin/entradas?page=" +
        this.currentPage +
        "&tipo_entrada=" +
        tipo_entrada +
        "&del=" +
        this.del +
        "&al=" +
        this.al +
        "&buscar=" +
        this.filter;
      axiosIns
        .get(url)
        .then((res) => {
          this.items = res.data.data;
          //this.currentPage=res.data.current_page
          this.totalRows = res.data.total;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    actualizarLista() {
      this.loading = true;
      this.listar();
    },
    listarCategorias() {
      var url = "/admin/categorias";
      axiosIns
        .get(url)
        .then((res) => {
          this.categoriaOpciones = res.data.map((g) => ({
            title: g.nombre,
            value: g.id,
          }));
        })
        .catch((err) => {
          console.log(err);
        });
    },
    listarTipoEntradas() {
      var url = "/admin/tipo-entradas-default";
      axiosIns
        .get(url)
        .then((res) => {
          this.tipoEntradaOpciones = res.data.map((g) => ({
            title: g.nombre,
            value: g.id,
          }));
        })
        .catch((err) => {
          console.log(err);
        });
    },
    confirmar_mover_papelera(id) {
      this.$swal({
        title: "Advertencia",
        text: "Confirme que esta seguro de mover a Papelera?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.mover_papelera(id);
        }
      });
    },
    mover_papelera(id) {
      axiosIns
        .put("/admin/entradas/mover-papelera/" + id)
        .then((res) => {
          this.$bvToast.toast("Exito", {
            title: "Se movio a papelera correctamente",
            variant: "success",
            solid: false,
          });
          this.actualizarLista();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    rango_fechas() {
      axiosIns
        .get("/admin/rango-fechas-entradas")
        .then((res) => {
          this.del = res.data[0];
          this.al = res.data[1];
          this.listar();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //imprimir pdf
    imprimirEntradaPdf(id) {
      axiosIns({
        url: "/admin/entradas/imprimir-pdf/" + id,
        method: "GET",
        responseType: "blob", // important
      })
        .then((response) => {
          var url = URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" })
          );
          var iframe = document.createElement("iframe");
          iframe.src = url;
          iframe.className = "pdfIframe";
          iframe.style.display = "none";
          iframe.style.visibility = "hidden";
          document.body.appendChild(iframe);
          iframe.focus();
          iframe.contentWindow.print();
          URL.revokeObjectURL(url);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>