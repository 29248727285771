<template>
  <b-modal
    id="modal-id-page-facebook"
    ref="my-modal"
    title="Pagina ID Facebook"
    no-close-on-backdrop
    ok-title="Guardar"
    cancel-variant="outline-secondary"
    cancel-title="Cerrar"
    @cancel="cerrarModal"
    @close="cerrarModal"
    @ok.prevent="validar"
  >
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <!-- key -->
          <b-col cols="12">
            <b-form-group
              label="Pagina ID Facebook"
              label-for="Pagina ID Facebook"
            >
              <validation-provider
                #default="{ errors }"
                name="Pagina ID Facebook"
                rules="required"
              >
                <b-form-input
                  id="Pagina ID Facebook"
                  v-model="key"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>
<script>
import axiosIns from "@/libs/axios";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BModal,
  BButton,
  VBModal,
  BAlert,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BForm,
  BFormSelect,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { required } from "@validations";

export default {
  components: {
    BButton,
    BModal,
    BAlert,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BFormSelect,
  },
  props: ["accion", "item"],
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  watch: {
    accion: function () {
      this.errores = [];
      if (this.accion) {
        this.abrir(this.accion, this.item);
      }
    },
  },
  data() {
    return {
      id: "",
      key: "",
      submitted: false,
      errores: [],
    };
  },
  methods: {
    abrir(accion, data = []) {
      switch (accion) {
        case "actualizar": {
          this.id = data["id"];
          this.key = data["valor"];

          break;
        }
      }
    },
    validar() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (!this.submitted) {
            this.submitted = true;
            this.actualizar();
          }
        }
      });
    },
    cerrarModal() {
      this.$emit("cerrarComponente");
    },
    actualizarOpcion(item) {
      this.$emit("actualizarPagId", item);
      this.submitted = false;
    },
    actualizar() {
      axiosIns
        .put("/opciones/" + this.id, {
          valor: this.key,
        })
        .then((res) => {
          this.$nextTick(() => {
            this.$refs["my-modal"].toggle("#toggle-btn");
          });
          this.actualizarOpcion();
          this.errores = [];
        })
        .catch((err) => {
          this.submitted = false;
          this.errores = err.response.data.errors;
        });
    },
  },
  mounted() {},
};
</script>