<template>
  <div>
    

    <!-- <b-form-input
      id="titulo"
      
      placeholder="id pagina facebook"
    />

    <b-button variant="secondary" @click="loginpage">
      Obtener Token pagina
    </b-button> -->
    <PageFacebook
      :accion="accion"
      :item="FacebookPagina"
      @cerrarComponente="cerrarComponente"
      @actualizarPagId="actualizarPagId"
    ></PageFacebook>
  </div>
</template>
<script>
import axiosIns from "@/libs/axios";
import {
  BButton,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
} from "bootstrap-vue";
import PageFacebook from "./PageFacebook.vue";
export default {
  components: {
    PageFacebook,
    BButton,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
  },
  // props: {
  //   facebook_pagina: {
  //     default: 374381306383341,
  //     required: true,
  //   },
  // },
  data() {
    return {
      accion: "",
      FacebookPagina: {
        id: "",
        valor: "",
      },
    };
  },
  created() {
    this.inicializarFacebook();
    this.getPagId();
  },
  methods: {
    async inicializarFacebook() {
      await this.loadFacebookSDK(document, "script", "facebook-jssdk");
      await this.initFacebook();
    },
    asignarUsuario(us) {
      console.log(us);
      this.$store.state.fusuario.usuario = us;
    },
    cerrarComponente() {
      this.accion = "";
      //this.item = [];
    },
    actualizarPagId() {
      this.loading = true;
      this.getPagId();
      this.accion = "";
      //this.item = [];
    },
    getPagId() {
      const api = this.mixing.opcions.find((g) => g.nombre === "facebook_page");
      if (api.id) {
        axiosIns
          .get("opciones/" + api.id)
          .then((res) => {
            //console.log(res)
            this.FacebookPagina.id = res.data.data.id;
            this.FacebookPagina.valor = res.data.data.valor;
            localStorage.setItem('fpid',this.FacebookPagina.valor);
            //this.totalRows = this.items.length
            //this.loading=false
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    async logInWithFacebook() {
      this.$store.state.fusuario.usuario = window.FB.login(
        function (response) {
          if (response.authResponse) {
            //console.log(response.authResponse);
            //alert("You are logged in &amp; cookie set!");
            //return response.authResponse;
            localStorage.setItem("fb", response.authResponse.accessToken);
            //this.$store.dispatch("fusuario/setUsuario", response.authResponse);

            // Now you can redirect the user or do an AJAX request to
            // a PHP script that grabs the signed request from the cookie.
          } else {
            alert("User cancelled login or did not fully authorize.");
          }
        },
        {
          scope: "pages_manage_posts",
        }
      );

      // console.log(this.$store.state.fusuario.usuario);

      // console.log(this.$store.state.fusuario.tokenpage);
      return false;
    },
    async loginpage() {
      // console.log(this.$store.state.fusuario.usuario);
      // console.log(this.$store.state.fusuario.usuario.accessToken);
      if (localStorage.getItem("fb")) {
        window.FB.api(
          `/${
            this.FacebookPagina.valor
          }?fields=access_token&access_token=${localStorage.getItem("fb")}`,
          ({ access_token }) => localStorage.setItem("fb_pag", access_token)
        );
        // `/${this.facebook_pagina}?fields=access_token&access_token=${localStorage.getItem('fb')}`,
        //   ({ access_token }) =>
        //     (localStorage.setItem('fb_pag',access_token));
      }
    },
    async login() {
      await this.logInWithFacebook();
    },
    async mensaje(Mensaje, tipo) {
      await this.$swal({
        title: Mensaje,

        icon: tipo,
        timer: 3000,
        confirmButtonText: "Aceptar",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    async logout() {
      window.FB.getLoginStatus((response) => {
        console.log(response);
        if (response.authResponse) {
          window.FB.logout((response) => {
           // console.log(response);
            //console.log("Logged Out!");
            this.mensaje(
              "Salio de la Session de Facebook Corretamente",
              "success"
            );
            localStorage.removeItem("fb");
            localStorage.removeItem("fb_pag");
          });
        }
      });
    },
    async initFacebook() {
      window.fbAsyncInit = function () {
        //const FB = window.FB;
        //console.log(FB);
        window.FB.init({
          appId: "437621834627488",
          xfbml: true,
          version: "v12.0",
        });
      };
    },
    async loadFacebookSDK(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    },
    abriComponente() {
      this.accion = "actualizar";
    },
  },
};
</script>
<style>
.button {
  color: white;
  min-width: 150px;
  background-color: #000000a1;
  height: 2.5rem;
  border-radius: 2rem;
  font-weight: 400;
  font-size: 0.8rem;
}
</style>