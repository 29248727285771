<template>
 
  <b-card title="Entradas">

  <FacebookLogin :facebook_pagina=pageId>
  </FacebookLogin>
    <b-tabs>
      <b-tab lazy title="Publicado">
        <Publicado/>
      </b-tab>
      <b-tab lazy title="Borradores">
        <Borrador/>
      </b-tab>
      <b-tab lazy title="Programado">
        <Programado/>
      </b-tab>
      <b-tab lazy title="Papelera">
        <Papelera/>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import axiosIns from "@/libs/axios";
import { BTabs, BTab, BCardText, BCard,BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { $themeColors } from "@themeConfig";
import Publicado from "@/views/aplicaciones/entradas/tabs/Publicado.vue";
import Borrador from "@/views/aplicaciones/entradas/tabs/Borrador.vue";
import Programado from "@/views/aplicaciones/entradas/tabs/Programado.vue";
import Papelera from "@/views/aplicaciones/entradas/tabs/Papelera.vue";
import FacebookLogin from "@/componentes/FacebookLogin.vue";

export default {
  components: {
    BButton,
    BTabs,
    BCardText,
    BTab,
    BCard,
    Publicado,
    Borrador,
    Programado,
    Papelera,
    FacebookLogin
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pageId:374381306383341,
      


    };
  },
  created() {},
  watch: {
  },
  methods: {
    
    
  },
};
</script>
<style lang="scss"></style>
